* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
html,body,address,blockquote,div,dl,form,h1,h2,h3,h4,h5,h6,ol,p,pre,table,ul,dd,dt,li,tbody,td,tfoot,th,thead,tr,button,del,ins,map,object,a,abbr,acronym,b,bdo,big,br,cite,code,dfn,em,i,img,kbd,q,samp,small,span,strong,sub,sup,tt,var,legend,
fieldset,p, aside, header, footer, section, article {
	border: medium none;
	margin: 0;
	padding: 0;
    
}

:focus
{
	outline: none !important;
}

img,fieldset 
{
	border: 0 none;
}

a
{
	border: none;
	outline: none;
}

input[type="submit"], select
{
	-webkit-appearance:none;
}
img
{
	border: none;
	height: auto;
	max-width: 100%;
	outline: none;
	vertical-align: middle;
}
iframe
{
    vertical-align: middle;
}
a:hover
{
	text-decoration: none;
    color:red;
}

.clear
{
	clear: both;
}
ul::after
{
	clear: both;
	content: "";
	display: block;
}
ul
{
	list-style:none;
}

body
{
	margin: 0;
	padding: 0;
	font-size: 16px;
	line-height: 27px;
	color: #474646;
    -webkit-font-smoothing: antialiased;
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
    height: 100%;

}
.signup-area {
    height: 100%;
}
.header-logo h2 {
    text-align: center;
    color: #000;
    font-size: 55px;
    font-weight: 700;
}
.signup-area-content {
    margin: 10px 0 60px;
    padding: 24px 50px;
    border-radius: 0;
    background: #fff;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.signup-area-content h2 {
    text-align: center;
    color: #002b78;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}
.signup-area-content form label {
    font-size: 18px;
    margin: 0 0 0 0;
    color: #000;
    font-weight: 600;
}
.signup-area-content form input::placeholder {
    color: #00000061;
    font-size: 15px;
    font-weight: 500;
}
.signup-area-content form input {
    padding: 19px;
    font-size: 17px;
    background: #ffffff;
    height: 70px;
    border: 1px solid #c3c3c391;
    margin-top: 7px;
}
.signup-area-content .form-control:focus, .form-select:focus {
    color: #000;
    box-shadow: unset;
	border: 1px solid #c3c3c391;
}
.signup-area-content h6 {
    color: #002b78;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 5px;
    display: flex;
    align-items: center;
    margin: 30px 0 22px 0;
}
.signup-area-content h6 i {
    background: #002b78;
    width: 20px;
    height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
	margin-left: 7px;
    cursor: pointer;
    font-size: 12px;
}
.signup-area-content form select {
    padding: 12px;
    font-size: 16px;
    background: #fff;
    height: 70px;
	border: 1px solid #c3c3c391;
}
.signup-area h2 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
}
.signup-area p {
    color: gray;
    font-weight: 400;
    font-size: 17px;
    margin-top: 4px;
}
.signup-area-content button {
    background: #002b78 !important;
    width: fit-content;
    color: #fff !important;
    padding: 12px 20px;
    display: flex;
    margin: 15px 0 0 auto;
}
.signup-area-content button a, button.proceed-btn.btn.btn-unset a {
    color: #fff !important;
    text-decoration: none !important;
}
.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}
.form-area-bottom form .mb-3 {
    display: flex;
    align-items: center;
}
.form-area-bottom .form-check input {
    padding: 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
	margin-top: 4px;
}	
.form-area-bottom form .form-check label {
    margin-right: 35px !important;
    margin-top: 0 !important;
    font-weight: 500;
}
 .form-check-input:checked {
    background-color: #002b78;
    border-color: #002b78;
}
.form-area-bottom label {
    font-size: 20px !important;
    margin: 10px 0 12px 0 !important;
}
.form-check-input:focus {
    box-shadow: none !important;
}
.pick-theme-area .header-logo h2 {
    font-size: 40px;
}
.pick-theme-content {
    padding: 30px;
    background: #00614D;
    border-radius: 10px;
}
.pick-theme-top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.pick-theme-content-inner {
    z-index: 9;
    position: relative;
    height: 100%;
}
.pick-theme-top-left h5 {
    color: #000;
    font-size: 30px;
}
.pick-theme-content-inner h5 {
    color: #fff;
    font-size: 25px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 37px;
}
.pick-theme-top-left p span, .pick-theme-top-right p span {
    margin-right: 10px;
    font-weight: 500;
    font-size: 15px;
}
.pick-theme-top-left p, .pick-theme-top-right p {
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
}

.isurance-area {
    background: rgb(255 255 255 / 17%);
    text-align: center;
    padding: 30px;
    border-radius: 10px;
}
.isurance-area h6 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
}
.edit-profile-area {
    background: #fff;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    margin: 0 25px;
    margin-top: 90px;
}
.edit-profile-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #002b78;
    padding: 20px;
}
.edit-profile-top button i {
    margin-right: 3px;
}
.edit-profile-top button {
    background: #ffffff !important;
    color: #002b78 !important;
    font-size: 14px;
    padding: 4px 12px;
    font-weight: 600;
    border-radius: 6px;
}
.edit-profile-area form label {
    color: #000;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}
.edit-profile-area form .form-control {
    border: none;
    border-bottom: 1px solid #00000021;
    height: 50px;
    border-radius: 0;
    padding-left: 0;
}
.edit-profile-area .form-control:focus {
    box-shadow: none;
}
.edit-logo h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
}
.edit-logo {
    width: 70px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    border-radius: 10px;
    min-width: 70px;
    min-height: 60px;
    position: relative;
}
.edit-logo-icon-outer {
    position: absolute;
    top: -6px;
    right: -8px;
}
.edit-logo-icon i {
    font-size: 13px;
}
.edit-logo-icon input {
    position: absolute;
    width: 25px;
    height: 25px;
    opacity: 0;
    min-width: 25px;
    min-height: 25px;
}
.edit-logo-icon {
    position: relative;
    width: 25px;
    background: #002b78;
    color: #fff;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7px;
}
.edit-content-bottom {
    padding: 35px 20px;
}
.insurance-selection h6 img {
    width: 23px;
    min-width: 23px;
    margin-left: 8px;
}
.insurance-selection h6 {
    color: #002b78;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.insurance-selection {
    margin-top: 15px;
}
.insurance-selection form {
    padding-top: 15px;
}
.insurance-selection form label {
    font-weight: 500;
    font-size: 17px;
}
.insurance-selection form .form-check {
    padding: 5px 25px;
}
.edit-content-bottom-btn {
    justify-content: end;
    display: flex;
}
button.proceed-btn.btn.btn-unset {
    color: #fff !important;
    background: #002b78 !important;
    font-size: 15px;
    font-weight: 400;
    padding: 7px 20px;
    border-radius: 7px;
}
button.preview-btn.btn.btn-unset {
    border: 2px solid #002b78 !important;
    background: none !important;
    color: #002b78 !important;
    font-size: 15px;
    font-weight: 700;
    padding: 7px 20px;
    border-radius: 7px;
}
.edit-content-bottom-btn button {
    margin: 0 0 0 8px;
}
.travel-option input {
    padding: 0 !important;
    height: 30px !important;
    width: 30px !important;
    margin-top: 3px !important;
    position: absolute;
    top: -10px;
    left: 16px;
    cursor: pointer;
}
.travel-insurance-area .signup-area-content {
    padding-top: 40px;
}
.travel-option {
    padding: 21px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #00000021;
}
.travel-insurance-right, .travel-insurance-left {
    padding-top: 30px !important;
    border-top: 1px solid #00000040;
    margin-top: 30px;
}
.travel-insurance-right h5 {
    color: #002b78;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 22px;
}
.travel-insurance-right form input {
    padding: 19px;
    font-size: 17px;
    background: #ffffff;
    height: 50px;
    border: none;
    margin-top: 0;
    border-bottom: 1px solid #00000024;
    border-radius: 0;
    padding-left: 0;
}
.travel-insurance-right .form-control:focus, .travel-insurance-right .form-select:focus {
    color: #000;
    box-shadow: unset;
    border-bottom: 1px solid #00000030 !important;
    border:none;
}
.travel-insurance-right form input::placeholder {
    color: #000;
    font-weight: 400;
}
.travel-insurance-right .form-control:disabled, .travel-insurance-right .form-control[readonly] {
    background-color: transparent;
    opacity: unset;
}
.travel-insurance-left h5 {
    color: #002b78;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 35px;
}
.inurance-area-content input {
    padding: 0;
    height: 30px !important;
    min-height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
    position: absolute;
    top: -9px;
    left: -3px;
}
.inurance-area-content {
    text-align: center;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    position: relative;
    box-shadow: rgb(255 255 255 / 0%) 0px 30px 60px -12px inset, rgb(155 155 155 / 55%) 0px 18px 36px -18px inset;
    border-bottom: 1px solid #002b78;
}
.travel-insurance-top-area {
    padding: 50px;
}
.travel-insurance-right button {
    padding: 9px 32px;
}
.sidebar h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 35px;
    font-weight: 700;
}
.sidebar ul li {
    color: #fff;
    font-size: 13px;
    padding: 13px 9px;
    align-items: center;
    font-weight: normal;
    margin: 12px 0;
    cursor: pointer;
    justify-content: center;
    display: flex;
}
.sidebar ul li img {
    width: 23px;
    min-width: 23px;
    filter: grayscale(1);
}
img.dasdboard {
    width: 19px !important;
    min-width: 19px !important;
}
.common-spacing {
    margin: 0 30px 30px 85px;
    padding-top: 90px;
    min-height: calc(100vh - 51px);
}
.policy-management-content {
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    overflow: hidden;
}
.policy-management-content thead {
    background: #e9ecef;
}
.policy-management-content thead tr th, .policy-management-content tr td {
    padding: 10px 20px;
    font-size: 14px;
}
.policy-management-content .table {
    margin: 0;
}
.policy-management-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.policy-management-top h5 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
}
.policy-management-top button {
    background: #002b78;
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 7px;
}
.header {
    padding: 15px 10px;
    z-index: 99;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    width: 100%;
}
.header-right button img {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 50%;
    margin-right: 3px;
    object-fit: cover;
}
.header-right button {
    background: none !important;
    border: none;
    color: #000 !important;
    font-size: 14px;
    font-weight: 500;
}
.btn-check:active+.btn-success:focus, .btn-check:checked+.btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show>.btn-success.dropdown-toggle:focus {
    box-shadow: none;
}
.header-right .dropdown-menu {
    font-size: 14px;
    border: 1px solid rgb(0 0 0 / 8%);
    border-radius: 10px;
    color: #000;
}
.header-right .dropdown-item.active, .header-right .dropdown-item:active {
    background-color: #002b78;
}
.add-policy .modal-header {
    border-bottom: none;
    padding-bottom: 5px;
}
.add-policy .modal-footer {
    border-top: none;
}
.add-policy .modal-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}
.add-policy .modal-content {
    border-radius: 20px;
    border: none;
    padding: 0 6px;
}
.add-policy .modal-body form label {
    margin: 0;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}
.add-policy .modal-body .form-control::placeholder {
    font-size: 14px;
}
.add-policy .modal-body .form-control {
    height: 45px;
    border: 1px solid #00000021;
    border-radius: 7px;
}
.documnet-upload-area i {
    font-size: 24px;
    color: darkgray;
}

.documnet-upload-area input {
    position: absolute;
    width: 80px;
    height: 80px;
    opacity: 0;
    min-height: 80px;
    top: 0;
    left: 0;
}
.documnet-upload-area {
    background: #80808024;
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.add-policy .modal-footer {
    justify-content: center;
}
.add-policy .modal-footer button {
    background: #002b78;
    border: none;
    font-size: 15px;
    padding: 9px 20px;
    border-radius: 8px;
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
 .form-control:focus {
    box-shadow: none;
}
.add-policy .modal-body form select {
    height: 45px;
    border: 1px solid #00000021;
    border-radius: 7px;
    font-size: 14px;
}
.btn-close:focus {
    box-shadow: none;
}
.policy-document p {
    font-size: 13px;
    font-style: oblique;
    color: #002b78;
    font-weight: 600;
}
.setting-txt, .setting-txt:hover {
    background: #002b78;
    color: #fff;
    text-decoration: none;
    padding: 3px 12px;
    border-radius: 10px;
    display: inline-block;
    font-size: 13px;
}
.settings-module {
    padding: 25px;
}
.settings-module h4 {
    font-size: 20px;
    margin-bottom: 20px;
}
.setting-box-body {
    padding: 15px;
    display: inline-block;
    border-radius: 11px;
    background: #efefef;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.setting-box-body p {
    font-size: 16px;
    font-weight: 600;
}
.setting-box-body .form-check-input {
    margin-top: 2px;
    height: 26px;
    width: 45px;
    margin-right: 7px;
}
.setting-box-body .form-check-label {
    font-size: 14px;
}
.no-adds {
    width: 122px;
}
button.Buy-btn.btn.btn-primary {
    background: #002b78;
    font-size: 16px;
    border: none;
    border-radius: 9px 9px;
    padding: 8px 18px;
    font-size: 13px;
}
.advertising-section h4 span {
    font-weight: 600;
    color: #002b78;
    border: 1px solid;
    padding: 6px 13px;
    border-radius: 11px;
    font-size: 15px;
    margin-left: 9px;
}
.advertising-section h4 {
    font-size: 18px;
}
.advertising-section div {
    margin-left: 23px;
}
.advertising-section {
    display: flex;
    align-items: center;
    /* border: 1px solid; */
    padding: 9px 41px;
    border-radius: 14px;
    background: #efefef;
}
.dashboard-box {
    background-image: linear-gradient(to left, #fff1ed 0%, #dfeaff 80%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    padding: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    top: 0px;
    border: 1px solid #002b781f;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgb(0 0 0 / 0%) 0px 30px 60px -30px;
}
.dashboard-box.second {
    background-image: linear-gradient(to left, #ededff 0%, #f0ffdf 80%);
}
.dashboard-box.third {
    background-image: linear-gradient(to left, #d8fbdc 0%, #eddaf1 80%);
}
.dashboard-box.four {
    background-image: linear-gradient(to left, #edfdff 0%, #dfeaff 80%);
}
.dashboard-box-right img {
    width: 70px;
}
.dashboard-box::after {
    content: "";
    position: absolute;
    /* background-image: url("../images/box-bg.png"); */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    opacity: 0.1;
}
.dashboard-box-left h6 {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
}
.dashboard-box-left h2 {
    color: #002b78;
    font-size: 38px;
    font-weight: 800;
}
.dashobard-chart {
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
}
.policy-management-area h4 {
    font-size: 19px;
    color: #000;
    font-weight: 600;
    margin: 40px 0 15px 0;
}
.tody-text {
    font-size: 22px !important;
    margin: 0 0 6px 0 !important;
}
.sales-text {
    margin-bottom: 12px;
    font-size: 14px;
}
.dashboard-content {
    background: #1e005f0d;
    padding: 20px;
    border-radius: 18px;
}
.graph-section {
    background: #1e005f0d;
    margin: 17px 0 0 0;
    padding: 16px;
    border-radius: 18px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgb(0 0 0 / 0%) 0px 30px 60px -30px;
    height: 100%;
}
.table-main tr td{
    vertical-align: middle;
}
.in-progress {
    background: #002b7833;
    color: #fff;
    padding: 2px 12px;
    font-size: 12px;
    border-radius: 15px;
    color: #002b78;
    font-weight: 700;
    border: 1px solid;
    display: inline-block;
    float: right;
}
.text-right{
    text-align: right;
}
.in-progress.red {
    background: #ff00003b;
    color: red;
}
.in-progress.green {
    color: green;
    background: #0080003d;
}
.progress {
    height: 9px;
}
.setting-txt i {
    margin-left: 6px;
}
.sidebar-content {
    height: 91%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.footer-text {
    font-size: 13px;
    color: #fff;
    text-align: center;
}
.login-top-logo h2 {
    color: #000;
    font-size: 38px;
    font-weight: 700;
}
.login-top-logo {
    padding: 20px;
}
.login-head {
    text-align: center;
}
.login-head h1 {
    color: #000;
    font-size: 60px;
    font-weight: 800;
    margin-bottom: 20px;
}
.login-head p {
    font-size: 19px;
    color: #000;
    font-weight: 500;
}
.login-head p a {
    color: #002b78 !important;
    font-weight: 700;
    text-decoration: underline !important;
    cursor: pointer;
}
.login-head {
    text-align: center;
    margin-bottom: 60px;
}
.login-form label, .website-title label {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
}
.login-form input::placeholder, .website-title::placeholder {
    font-size: 14px;
}
.login-form input, .website-title input {
    border: none;
    border-bottom: 1px solid #00000021;
    border-radius: 0;
    height: 55px;
    padding-left: 0;
    font-size: 15px;
}
.form-control:focus {
    background-color: transparent;
    border-color: #00000021;
}
.login-form a {
    color: #002b78 !important;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline !important;
    cursor: pointer;
}
.continue-btn button i {
    margin-left: 8px;
}
.login-btns-right {
    text-align: center;
    padding: 0 50px;
}
.continue-btn button a {
    color: #002b78 !important;
    border: 2px solid #002b78;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 50px 0 0 0;
    padding: 10px 50px;
    border-radius: 10px;
    text-decoration: unset !important;
    font-size: 15px;
}
.continue-btn button {
    padding: 0;
}
.login-btns-right ul li  {
    cursor: pointer;
}
.login-btns-right ul li img {
    width: 30px;
    min-width: 30px;
}
li.google-btn {
    background: #4285f4;
    display: flex;
    border: 1px solid #4285f4 ;
}
li.google-btn img {
    background: #fff;
    width: 60px !important;
    min-width: 60px !important;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
li.google-btn span, li.facebook-btn span, li.apple-btn span {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
}
li.facebook-btn {
    display: flex;
    align-items: center;
    background: #2a5297;
    border: 1px solid #2a5297;
}
li.facebook-btn img {
    background: #fff;
    width: 60px !important;
    min-width: 60px !important;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
li.apple-btn {
    display: flex;
    align-items: center;
    background: #000;
    border: 1px solid #000;
}
li.apple-btn img {
    background: #fff;
    width: 60px !important;
    min-width: 60px !important;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-btns-right ul li {
    margin: 15px 0;
}
.login-form {
    border-right: 1px solid #dedede;
    padding-right: 60px;
    position: relative;
}
.login-form p {
    position: absolute;
    right: -9px;
    top: 50%;
    transform: translate(0, -50%);
    background: #fff;
    padding: 2px 0;
}
.login-footer {
    text-align: center;
    margin-top: 90px;
}
.login-footer ul li {
    color: #7a8489;
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
    margin: 5px 10px;
}

.login-footer ul {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-footer p span {
    text-decoration: underline;
    font-weight: 700;
}
.login-footer p {
    color: #7a8489;
    font-weight: 500;
    font-size: 15px;         
}
.get-started-section{
    height: 97vh;
}
.get-content h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 22px;
    color: #000;
}
.get-content h6 {
    font-size: 19px;
    margin-bottom: 12px;
    color: #000;
}
.get-content {
    padding: 100px 130px;
}
.get-content .form-check label {
    color: #000;
    font-weight: 500;
    font-size: 15px;
}
.get-content .form-check {
    padding: 10px;
    background: #23488a1c;
    margin-bottom: 7px;
    border-radius: 12px;
    cursor: pointer;
}
.get-content 
 .active.form-check {
    border: 1px solid #214689;
}
.get-content .form-check-input {
    margin-left: 1px !important;
    margin-right: 12px;
    cursor: pointer;
}
.bg-gradient {
    background-color: #002b78;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-top-logo {
    padding: 20px;
    position: absolute;
    top: 2px;
    left: 30px;
}
.bg-gradient img {
    border: 9px solid #fff;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 63%) 0px 20px 100px 0px, rgb(0 0 0 / 63%) 0px 20px 60px 0px;
}
.login-section {
    padding: 50px 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background: #fff
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
button.get-started-btn-main a {
    color: #002b78 !important;
    text-decoration: none !important;
}
button.get-started-btn-main {
    background: none !important;
    border: none !important;
    padding: 0;
}

button.get-started-btn-main a {
    background: none !important;
    color: #002b78 !important;
    border: 2px solid #002b78 !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 50px 0 0 0;
    padding: 10px 50px;
    border-radius: 10px;
    text-decoration: unset !important;
    font-size: 15px;
}
button.get-started-btn-main a {
    background: none !important;
    color: #002b78 !important;
    border: 2px solid #002b78 !important;
    font-weight: 600;
    text-decoration: unset !important;
    font-size: 15px;
}
.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-left {
    display: flex;
    align-items: center;
}
img.desktop-icon {
    width: 28px;
    min-width: 28px;
}
img.mobile-icon {
    width: 21px;
    min-width: 21px;
}
.header-logo-top h2 {
    color: #000;
    font-weight: 700;
    font-size: 26px;
}
.header-top-button {
    display: flex;
    align-items: center;
}
.desktop-mobile-icon {
    border-right: 1px solid #00000029;
    border-left: 1px solid #00000026;
}
.select-color-araea label {
    margin: 0;
    color: #000;
    font-weight: 600;
    margin-right: 10px;
}
.desktop-mobile-icon img {
    margin: 0 15px;
    cursor: pointer;
}
img.mobile-icon {
    width: 16px;
    min-width: 16px;
}
.url-coonect-domain {
    background: #e9ecef75;
    padding: 8px 20px;
    border-radius: 8px;
    width: 800px;
    margin-left: 40px;
}
.pick-theme-select-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    position: relative;
}
.url-coonect-domain p {
    font-size: 14px;
    color: #000;
}
.url-coonect-domain a {
    color: #0b337c !important;
    font-weight: 700;
    margin-left: 12px;
}
.header-top-button button {
    background: #0c347c !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 7px;
    cursor: pointer;
}
.header-top-button h6 {
    color: #042e79;
    font-weight: 600;
    margin: 0 20px;
    cursor: pointer;
}
.header-top-button p {
    color: #000;
    cursor: pointer;
    font-weight: 600;
}
.sidebar {
    width: 75px;
    height: 100vh;
    padding: 12px;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    background: #ffffff;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    z-index: 9;
}
.sidebar-content ul {
    margin-top: 60px;
}
.sidebar ul li.active img {
    filter: unset;
}
.pick-theme-area {
    margin: 0 0 0 75px;
    padding-top: 65px;
}
img.logout {
    transform: rotate(180deg);
}
.complete-profile .w3-light-grey {
    background: #80808040;
    height: 15px;
    border-radius: 130px;
    overflow: hidden;
    margin-bottom: 6px;
}
.complete-profile .w3-light-grey .w3-container.w3-blue {
    background: #002b78;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 11px;
}
.complete-profile {
    margin-top: 20px;
}
.complete-profile a {
    color: #002b78;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
}
.header-logo.signup-head h2 {
    text-align: center;
    color: #000;
    font-size: 50px;
    font-weight: 700;
}
.screen-img-box {
    text-align: center;
    width: 60%;
    position: relative;
}
.screen-head-area img {
    border: none;
    box-shadow: none;
    width: 40px;
    min-width: 40px;
}
.screen-img-box-content {
    position: absolute;
    top: 13px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 95%;
}
.phone-icon-bottom img {
    border: none;
    box-shadow: none;
}
.screen-head-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
}
.screen-head-area-left p i {
    color: #002b78;
}
.screen-head-area-left p {
    color: #000;
    font-size: 13px;
    font-weight: 500;
}
.screen-head-area-left h6 {
    font-size: 14px;
    color: #000;
    font-weight: 700;
}
.screen-head-area-left {
    text-align: left;
}
.phone-icon-bottom img {
    width: 70px;
    min-width: 70px;
}
.screen-img-body .isurance-area img {
    border: none;
    padding: 0;
    border-radius: 0;
    background: unset !important;
    width: 44px;
}
.screen-img-body {
    padding: 25px 25px 15px 25px;
}
.screen-img-body .isurance-area {
    padding: 12px;
    background: rgb(255 255 255 / 23%);
}
.screen-img-body .isurance-area h6 {
    font-size: 14px;
}
.phone-icon-bottom p {
    color: #194085;
    font-weight: 700;
}

.insurance-selection.get-started-selection {
    margin-top: 30px;
}
.insurance-selection.get-started-selection h6 {
    margin-bottom: 0;
}
.insurance-selection.get-started-selection .form-check {
    background: none;
    padding: 0;
}
.insurance-selection.get-started-selection label {
    font-weight: 500;
}
.login-mid-area.row {
    padding: 50px 0;
}
.login-mid-area .continue-btn button a {
    margin: 80px 0 0 0;
}
.pick-theme-logo-area {
    display: flex;
    justify-content: space-between;
}
.pick-theme-logo-area p {
    background: #fff;
    color: #002b78;
    font-weight: 600;
    font-size: 20px;
    padding: 5px 8px;
    border-radius: 6px;
    margin-bottom: 7px;
}
.banner-head-area {
    padding: 65px 0 0;
}
.banner-head-area h2 {
    text-align: center;
    color: #CEE07C;
    font-size: 75px;
    font-weight: 700;
    margin-bottom: 22px;
}
.banner-head-area h2 span {
    color: #ffffff;
    font-weight: 800;
}
.banner-search-area p {
    background: #ffffff2e;
    padding: 20px 70px;
    border: 1px solid #ffffff3d;
    border-radius: 9px;
    color: #fff;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    font-size: 30px;
}
.pick-theme-form {
    padding: 20px;
}
.pick-theme-form-area label {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
.edit-profile-area form input {
    background: none;
    border-bottom: 1px solid #ffffff26 !important;
    height: 30px !important;
}
.edit-profile-area form input::placeholder {
    color: #ffffffb3;
    font-size: 13px;
    font-weight: 400;

}
.select-theme-bottom .get-content {
    padding: 20px;
}
.get-content label {
    color: #000;
    font-weight: 600;
}
.color-list {
    list-style: none;
    display: flex;
  }
  .color-list li{
    display: block;
    margin: 0 4px;
    border: 1px solid #dbdbdb;
    border-radius: 50px;
  }
  .color-list li a{
    display: block;
  }
  .color-list li:hover{
    border: 1px solid #000;
  }
  .color-list li.current-color {
    border: 1px solid #000;
  }
  .color-list span {
    height: 32px;
    width: 32px;
    background: gray;
    display: block;
    margin: 6px 6px;
    border-radius: 50px;
  }
  .pick-theme-select-area-right {
    display: flex;
    align-items: center;
}
.select-color-araea {
    display: inline-flex;
    align-items: center;
}
.insurance-content-mid {
    width: 100%;
    padding: 50px 0;
}
.overlay-banner {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background: rgb(255 255 255 / 17%);
    margin-bottom: 50px;
    box-shadow: rgb(0 0 0 / 63%) 0px 20px 100px 0px, rgb(0 0 0 / 63%) 0px 20px 60px 0px;
    border: 5px solid #fff;
}
.overlay-banner-top h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}
.banner-mid-outer {
    display: flex;
    margin-top: 40px;
}
.banner-content-right img {
    width: 400px;
}
.banner-content-left {
    text-align: left;
}
.banner-content-left h6 {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 21px;
}
.banner-content-left ul li img {
    width: 24px;
    min-width: 24px;
    margin-right: 10px;
}
.banner-content-left ul li {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 0;
}
.banner-content-left h4 {
    color: #cee07c;
    font-weight: 800;
    margin: 20px 0;
}
.banner-content-left p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}
.pick-theme-logo-area a {
    color: #fff !important;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
}
.login-top-logo img {
    width: 200px;
}
.header-logo-top img {
    width: 150px;
}
.header-logo.signup-head img {
    width: 260px;
    display: flex;
    margin: 20px auto;
}
.user-info-top h6 {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
}
.user-info-top h5 {
    font-size: 30px;
    text-transform: math-auto;
    font-weight: 600;
}
.footer {
    background: #002b78;
    padding: 12px;
    z-index: 0;
    position: relative;
}
.pick-theme-content::-webkit-scrollbar {
    width: 6px;
  }
   
.pick-theme-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
.pick-theme-content::-webkit-scrollbar-thumb {
    background-color: rgb(255 255 255 / 17%);
  }
.under-pick-theme {
    margin: 20px 0;
}
.pick-theme-select-area-right button {
    background: #002b78;
    color: #fff !important;
    font-weight: 600;
    padding: 5px 20px;
    border-radius: 8px;
    margin-left: 50px;
}
.user-info-top {
    text-align: center;
    display: flex;
    align-items: baseline;
}
.user-info-top i {
    color: #fff;
    margin-left: 8px;
    cursor: pointer;
}
h6.phone-number i.fa-solid.fa-pen-to-square {
    color: #fff;
    cursor: pointer;
    margin-left: 8px;
    font-size: 20px;
}
h6.phone-number {
    display: flex;
    align-items: center;
}
.phone-area-right {
    text-align: center;
}
.banner-search-area p span {
    background: #cee073;
    color: #00614d;
    font-weight: 800;
    padding: 6px 8px;
}
img.your-logo {
    position: absolute;
    width: 160px;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
}